/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Fabian Greimel",
  title: "A warm welcome to my website!",
  subTitle: emoji(
 //   "Ich bin Fabian Greimel und ich bin passionierter ITler & Developer 🚀 mit Erfahrung in der Entwicklung von  Web und Mobile applications with Java / Spring / Kotlin / JavaScript / Reactjs / Nodejs / und weiteren spannenden Bibliotheken und Frameworks."
"My name is Fabian Greimel and my heart beats for IT. In addition to IT, I have a great interest in the health sector, which is why I am studying health informatics. In my spare time, I enjoy photography, designing and developing web/mobile applications. I mostly use Kotlin, Jetpack Compose, Spring, JavaScript, React, NodeJS or SwiftUI for this.." 

    ),
  resumeLink:
    "https://drive.google.com/file/d/1hjOeYgtLLZZ-SCWq5TcR7QID8BdQZQgS/view?usp=sharing",
  displayGreeting: true
};

// Social Media Links

const socialMediaLinks = {
    github: "https://github.com/greifadler",
    gmail: "fabiangreimel@gmail.com",
  instagram: "http://instagram.com/styrialabs",
  display: true
};

// Skills Section

const skillsSection = {
  title: "What am I doing",
  subTitle: "I AM A DEVELOPER WITH A GROWING FOCUS IN THE EHEALTH & MEDICAL FIELD",
  skills: [
    
      "⚡ In my spare time, I love working with IT security. Our data needs to be protected, especially in the healthcare sector."
    ,
      "⚡ There are thousands of diseases, but only one health. According to this motto, I would like to preserve the health of all of us and support & improve it with the help of digitalisation.",
    
      "⚡ Something new every day! According to this principle, I expand my knowledge in the IT & health sector every day."
    
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

    softwareSkills: [
        {
            skillName: "Kotlin & Compose",
            fontAwesomeClassname: "fab fa-android"
        },
        {
            skillName: "Swift",
            fontAwesomeClassname: "fab fa-swift"
        },
        {
            skillName: "Java",
            fontAwesomeClassname: "fab fa-java"
        },
    {
      skillName: "HTML",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "CSS",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "NodeJS",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "Figma",
      fontAwesomeClassname: "fab fa-figma"
    },
    {
      skillName: "SQL",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "Windows",
      fontAwesomeClassname: "fab fa-windows"
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "HTBLA Kaindorf an der Sulm",
      logo: require("./assets/images/kaindorf-logo.png"),
      subHeader: "Computer Science & Economics",
      duration: "September 2014 - June 2019",
          desc: "At the technical college I learnt programming skills in Java, C, C#, HTML, CSS, JS and SQL. I also learnt how to use agile programming (Scrum) and how to carry out my own projects. Business administration and management and specialising in IT security were further focal points of my training.",
      descBullets: [
        "Java, C#, C, HTML, CSS, JS",
          "SQL, NoSQL, Oracle, Postgres, MySQL, Neo4J, Redis",
        "IT-Security"
      ]
    },
    {
      schoolName: "University of applied sciences Joanneum",
      logo: require("./assets/images/fhj-logo.png"),
        subHeader: "Bachelor's degree programme in medical informatics & eHealth",
      duration: "September 2021 - now",
        descBullets: [
            "Understanding the Austrian & international healthcare systems",
            "How can the documentation effort in the medical field be simplified?",
            "How can digitalisation simplify and optimise life in the health and care sector?"
        ]
    },    
    {
      schoolName: "University of applied sciences Inholland Haarlem",
      logo: require("./assets/images/inholland-logo.png"),
        subHeader: "Semester abroad in the field of mobile development",
      duration: "September 2023 - January 2024",
        descBullets: [
            "Learn how to independently create a functional mobile application including a backend for a client in a team.",
            "Creating an application called Anno Amsterdam, which uses AR and maps to show the historical details of the buildings in Amsterdam in a simple and intuitive way.",
            "Learning UI and UX skills with prototypes on paper and in Figma.",
            "Native development of applications for Android and iOS with Jetpack Compose and SwiftUI"
        ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Codings", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Design",
      progressPercentage: "70%"
    },
    {
      Stack: "Photography",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineer - Mobile & Web",
      company: "Liebrecht & Haas GmbH",
          companylogo: require("./assets/images/liebrecht-haas-logo.png"),
      date: "January 2023 – now",
      desc: "Development of web projects with PHP and Laravel.  Assistance in the development of mobile applications with Flutter.",
      descBullets: [
        "PHP, Laravell & Flutter",
          "JS, CSS & HTML"
      ]
    },
    {
      role: "Software Engineer - Full Stack",
      company: "Promed GmbH",
          companylogo: require("./assets/images/promed-logo.png"),
      date: "March 2021 – December 2022",
      desc: "Implementation of projects in Java and Kotlin. Focus on web applications, REST APIs and Android apps.",
      descBullets: [
        "Web development projects with Java and Spring",
          "Native Android apps with Kotlin and Jetpack Compose",
        "Wordpress projects"
      ]
    },
    {
      role: "IT technician",
      company: "Ennstal [IT]",
      companylogo: require("./assets/images/ennstal-it-logo.png"),
      date: "Fall 2019",
        desc: "Over a period of 2 months, I recorded, analysed and solved various problems for our customers. Examples of my work there include solving application errors, rectifying server failures, implementing server changes, setting up networks and rescuing data in the event of virus attacks."
    },
      {
          role: "Developer in NodeJS",
          company: "Pink Robin GmbH",
          companylogo: require("./assets/images/pink-robin-logo.png"),
          date: "Summer 2018",
          desc: "As a Node.js programmer, I developed an independent messaging service API as part of my diploma thesis. This sends Android & IOS push messages, emails and SMS."
      }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "My projects",
  subtitle: "PROJECTS THAT I HAVE REALISED SO FAR",
  projects: [
    {
      image: require("./assets/images/anno-adam.png"),
      projectName: "Anno Amsterdam",
      projectDesc: "A mobile application for the residents & tourists of Amsterdam to discover and learn more about the historic buildings using AR. ",
      footerLink: [
        {
          name: "To the Figma designs",
          url: "https://www.figma.com/file/sgxBwUCgij0C1eRSYI93jf/Anno-Amsterdam?type=design&node-id=1712%3A106&mode=design&t=mRgv6uVz1PiS243j-1"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/newsreader-compose.png"),
      projectName: "The Reader",
      projectDesc: "An Android app to read newspaper articles developed with Jetpack Compose and Kotlin",
      footerLink: [
        {
          name: "Github repo",
          url: "https://github.com/greifadler/newsreader"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/pokedex-ios.png"),
      projectName: "Pokedex",
      projectDesc: "An iOS app developed with Swift & SwiftUI to save your favourite Pokemons and search for Pokemon.",
      footerLink: [
        {
          name: "Github repo",
          url: "https://github.com/greifadler/pokedex-ios"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/osg-logo.png"),
      projectName: "Osteopathin Lassing",
      projectDesc: "A website to present the activities of osteopath Sonja Greimel.",
      footerLink: [
        {
          name: "To the website",
          url: "http://heilmasseurin-lassing.at/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/up-logo.png"),
      projectName: "Unfold Potential",
        projectDesc: "A website for the clinical psychologist and cranio-sacral therapist Kathrin Großschedl. The website represents the work of Unfold Potential and offers the function to book and charge an appointment directly online.",
      footerLink: [
        {
          name: "To the website",
              url: "https://unfold-potential.co/"
        }
      ]
      },
      {
        image: require("./assets/images/frischeparadies-thomueller.png"),
        projectName: "Frischeparadies Thomüller",
          projectDesc: "A website for the delicatessen Thomüller in Graz. The website represents the business of the two Thomüller brothers and offers the possibility to contact the company directly online.",
        footerLink: [
          {
            name: "To the website",
                url: "https://frischeparadies.at/"
          }
        ]
        },
        {
          image: require("./assets/images/gut-geordnet.png"),
          projectName: "Gut Geordnet",
            projectDesc: "A website for Manuela Gutschlhofer, which represents her company to create tidiness.",
          footerLink: [
            {
              name: "To the website",
                  url: "https://www.gutgeordnet.at/"
            }
          ]
          },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Some photos you have worked on

const photos = {
  title: "Meine bisherigen Fotos",
  subtitle: "Meine liebsten Fotos die ich mit meiner Kamera gemacht habe",
  pictures: [
    {
      image: require("./assets/images/osg-logo.png"),
      projectName: "Osteopathin Lassing",
      projectDesc: "Eine Website zur Präsentation der Tätigkeiten der Osteopathin Sonja Greimel.",
      footerLink: [
        {
          name: "Zur Website",
          url: "http://heilmasseurin-lassing.at/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/up-logo.png"),
      projectName: "Unfold Potential",
        projectDesc: "Eine Website für die klinische Psychologin und CranioSacral Therapeutin Kathrin Großschedl. Die Website repräsentiert die Arbeit von Unfold Potential und bietet die Funktion direkt online einen Termin zu buchen und zu verrechnen.",
      footerLink: [
        {
          name: "Zur Website",
              url: "https://unfold-potential.co/"
        }
      ]
      },
      {
        image: require("./assets/images/frischeparadies-thomueller.png"),
        projectName: "Frischeparadies Thomüller",
          projectDesc: "Eine Website für die Feinkosthändler Thomüller in Graz. Die Website repräsentiert den Betrieb der beiden Thomüller Brüder und bietet die Funktion direkt online in Kontakt mit dem Unternehmen zu treten.",
        footerLink: [
          {
            name: "Zur Website",
                url: "https://frischeparadies.at/"
          }
        ]
        },


  ],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: "Papers and certificates 🏆",
  subtitle:
    "Project work, certificates, awards and much more that I have done so far!",

  achievementsCards: [
    {
      title: "How to hack a company - collecting information",
      subtitle:
          "In this 30-page seminar paper, I show methods and techniques for gathering information about a company. From a company perspective, this guide can be used to prepare for an attack and to understand how an attacker thinks and how best to protect themselves.",
      image: require("./assets/images/hu-is-q.png"),
      footerLink: [
        {
          name: "Seminar paper",
              url: "https://drive.google.com/file/d/1AVXRLSPtiZrDmbrWnAqql9Hw1_0cHo7f/view?usp=sharing"
        }
      ]
    },
  
    {
      image: require("./assets/images/wastebox-logo.png"),
      title: "Conception & implementation of a messaging service",
      subtitle: "In the project, a microservice was created that is used by the existing wastebox.biz app. The service sends various notifications to wastebox clients. The specific functionalities of the microservice are SMS, Android push notifications, iOS push notifications and e-mail.",
      footerLink: [
          {
              name: "Diploma thesis",
              url: "https://drive.google.com/file/d/1QG7NGZkKqi9pu47xu6NHuLSLuLMLXLrK/view?usp=sharing"
          }
      ]
  },

    {
      title: "Cisco Cybersecurity certificate",
      subtitle:
        "As part of the Cisco Networking Academy, I received two cybersecurity certificates, which enabled me to deepen my knowledge in the area of security.",
        image: require("./assets/images/cisco-cybersecurity.png"),
      footerLink: [
        {
              name: "Introduction to Cybersecurity",
              url: "https://drive.google.com/file/d/1N6RAfSa6hVF7Zk0555R8GNFis-I38P2i/view?usp=sharing"
          },
          {
              name: "Cybersecurity Essentials",
              url: "https://drive.google.com/file/d/17dpNRknRbYNrliWp_OLZtKU2zLcyj8Uc/view?usp=sharing"
          }
      ]
    },

    {
      title: "Cisco CCNA - Routing & Switching",
      subtitle: "Network technology certificate from Cisco for network technology knowledge in the area of routing & switching",
      image: require("./assets/images/ccna.jpg"),
      footerLink: [
        {name: "Certificate", url: ""},
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blog",
  subtitle:
    "I have published some of my projects as blog entries. I love writing and enjoy sharing my knowledge with others.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
        url: "https://medium.com/@fabiangreimel/smartmirror-mit-react-4e5a8b5f9586",
      title: "Smartmirror out of the Box",
      description:
        "In this project, I built a smart mirror from an old television, a Raspberry PI and my own React app."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: "Contact information ☎️",
  subtitle:
    "Do you have any project ideas or just want to get in touch? My inbox is open to everyone, just write to me.",
  number: "+43 664 91 74 774",
  email_address: "fabiangreimel@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  photos,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
